// Colours
$white: #fafafa;
$text-color: #3f4774 !default;
$bg-color: $white !default;

$brand-color: #556bec !default;
$brand-color-light: #7c90fc !default;
$brand-color-dark: #3a4376 !default;
$brand-color-lighter: lighten($brand-color-light, 15%) !default;
$brand-color-darker: darken($brand-color-dark, 15%) !default;
$accent-color: #6e36da !default;
$accent-color-light: #8c60e2;

$gray-color: #d6dbf5 !default;
$gray-color-light: lighten($gray-color, 3%) !default;
$gray-color-dark: darken($gray-color, 50%) !default;
$darkblue: #1c1c31;
$pinkishwhite: #ffe4e8;

// Theming

//Light theme
:root.light {
  --relcolor: #{lighten($pinkishwhite, 20%)};
  --bg-color: #{lighten($pinkishwhite, 4%)};
  --border-image: linear-gradient(
      to bottom,
      $brand-color-light 0%,
      $accent-color-light 100%
    )
    1;
  --text-color: #{$text-color};
  --subtext-color: #{$gray-color-dark};
  --link-color: #{$brand-color};
  --hover-color: #{$brand-color-dark};
  --accent-color: #{$brand-color-lighter};
  --theme-color: #{$brand-color};
  --hr-color: #{$brand-color-light};
}

//Dark theme
:root,
:root.dark {
  --relcolor: #{$brand-color-darker};
  $text-color: lighten($pinkishwhite, 2%);
  --bg-color: #{$darkblue};
  --text-color: #{$text-color};
  --subtext-color: #{$gray-color};
  --link-color: #{$brand-color-light};
  --hover-color: #{$brand-color};
  --accent-color: #{$brand-color};
  --theme-color: #{$brand-color};
  --hr-color: #{$brand-color-dark};
}

@media (prefers-color-scheme: dark) {
  :root.dark {
    --relcolor: #{$brand-color-darker};
    $text-color: lighten($pinkishwhite, 2%);
    --bg-color: #{$darkblue};
    --text-color: #{$text-color};
    --subtext-color: #{$gray-color};
    --link-color: #{$brand-color-light};
    --hover-color: #{$brand-color};
    --accent-color: #{$brand-color};
    --theme-color: #{$brand-color};
    --hr-color: #{$brand-color-dark};
  }
}
