// About Styles

.about-section {
  margin-bottom: $spacing-unit;

  .description {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(4, auto) 1fr repeat(4,auto);
    grid-template-rows: auto;
    width: 100%;
    grid-column-gap: 3%;
  }

  figure,
  article {
    grid-column: 1 / -1;
    grid-row: span 1;

    @media (min-width: em(900)) {
      grid-column: span 4;
    }
  }

  figure {
    @extend .fade;
    justify-self: center;
    position: relative;
    align-self: center;
    margin: 0;

    img {
      margin-bottom: em(24);
      max-width: em(200);
      width: 100%;
      object-fit: cover;

      @media (min-width: em(900)) {
        margin: 0;
      }
    }
  }

  article {
    align-self: center;
    max-width: em(700);
    margin-bottom: 0;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}