// // Entry page
.case-studies-content {

  h1 {
    margin-bottom: $spacing-unit * 1.5;
  }

  ul {
    display: grid;
    // grid-gap:  0;
    grid-row-gap: em(16);
    grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(1, 1fr);
    list-style: none;

    @media only screen and (min-width: em(600)) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: em(24);
    }

    @media only screen and (min-width: em(1200)) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: em(40);
    }

    @media only screen and (min-width: em(1800)) {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: em(48);
    }

    li {
      a {
        color: var(--text-color);
        display: block;
        height: 100%;
        text-decoration: none;
        width: 100%;

        &:hover{
          figure {
            .thumb-container {
                filter: saturate(100%);
                &:after {
                  opacity: 1;
                }
            }
            span {
              opacity: 1;

              &.project-subtitle {
                opacity: 1;
              }

              &.project-title {
                color: var(--link-color);
              }

              &.tags {
                .tag-each {
                  opacity: 1;
                }
              }
            }
          }
        }

        figure {
          overflow: hidden;
          margin-bottom: 0;

          .thumb-container {
            @include shadow-z0;
            @include transition(ease-in, .4s);
            height: auto;
            overflow: hidden;
            padding-bottom: 0%;
            position: relative;

            &:after {
              @include transition(ease-in, .4s);
              background: rgba($gray-color,.2);
              border-radius: 16px;
              content: "";
              display: block;
              height: 100%;
              opacity: 0;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
          figcaption {
            align-items: center;
            display: block;

          span {
            @include transition(ease-in, .4s);
            opacity: .8;

            &.project-title {
              display: block;
              font-size: $lg-font-size;
              margin-top: 16px;
              min-width: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: inherit;
              color: var(--text-color);
            }

            &.project-subtitle {
              font-size: 14px;
              margin-top: 8px;
              color: var(--subtext-color);
              opacity: 0;
            }

            &.tags {
              display: none;
              justify-content: flex-start;
              flex-direction: row;
              flex-wrap: wrap;
              margin-top: 4px;

                .tag-each {
                  background: $white;
                  border-radius: 8px;
                  border: 1px solid $gray-color;
                  flex-shrink: 0;
                  margin-right: 8px;
                  margin-top: 4px;
                  opacity: .6;
                  padding: 4px 6px;
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Individual case study styling
.case-study {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto;
  justify-content: center;
  margin: em(32) auto;
  padding: em(32) 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol,
  iframe,
  figure,
  blockquote,
  .back-to-section {
    align-self: center;
    grid-column: 1 / -1;
    justify-self: center;
    margin: 20px auto;
    max-width: 700px;
    width: 100%;

    @media (min-width: em(1000)) {
      grid-column: 2 / -2;
    }
  }

  ul,
  ol {
    margin: 10px auto;

    ol {
      list-style-type: lower-alpha;;
    }
  }

  h1 {
    margin-bottom: 0;
  }

  h2,
  h3, {
    margin-bottom:  $spacing-unit / 2;
  }

  h3 {
    // font-weight: 500;
    margin-top: $spacing-unit;
  }

  blockquote {
    margin: em(8) auto;
    &:last-child {
      margin-bottom: em(20);
    }
  }

  figure {
    grid-column: 1 / -1;
    margin: em(20) auto;
    max-width: none;

    @media (min-width: em(1000)) {
      grid-column: 2 / -2;
    }

    img {
      border-radius: 16px;
      margin: 0 auto;
      max-width: em(1200);
      padding: 10px;
      width: 100%;

      & + img {
        margin-top: em(20);
      }

      &.no-brd {
        border: none;
      }

      &.mid-size {
        max-width: em(800);
      }

      &.small-size {
        max-width: em(500);
      }
    }

    figcaption {
      font-size: em(14);
      font-weight: $base-font-weight;
      margin: em(10, 14) auto 0;
      max-width: em(500, 14);
      text-align: center;
      line-height: 1.3em;
    }
  }

  .videos, .gallery {
    display: grid; 
    grid-column: 1 / -1;
    grid-template-columns: 1fr 1fr; 
    margin: em(20) auto;
    max-width: none;
    width: 100%;


    @media (min-width: em(1000)) {
      grid-column: 2 / -2;
    }

    video {
      width:100%;
      border-radius: 20px;
      padding: 12px;
    }

    figure {
      grid-column: 1 / -1;
      margin: 0;
      width:100%;

      @media (min-width: em(1440)) {
        grid-column: initial;
      }

    }
  }

  p {
    line-height: em(30,18);
  }

  p:not(.project-details) {
    font-size: em(18);
    margin: 0 auto em(16);

    &.first-para {
      // @include shadow-z0;
      background: var(--bg-color);
      border-left: 4px solid $brand-color;
      border-radius: 3px;
      color: var(--text-color);
      font-size: 20px;
      margin-top: em(10, 18);
      padding: em(20, 18);
    }

    &.list-title {
      margin: 10px auto 0;
    }
  }

  a {
    &.emphasis {
      border-left: 3px solid $brand-color;
      border-right: 3px solid $brand-color;
      border-radius: 3px;
      padding: 0 em(16, 18);
      @include shadow-z0;

      &:hover {
        border-left: 3px solid $brand-color-dark;
        border-right: 3px solid $brand-color-dark;
      }
    }
  }

  ul,
  ol {
    padding-left: $spacing-unit;
    font-size: 18px;

    li {
      margin-bottom: 12px;
    }

    li > ul {
      // font-size: 1em;
      margin: 0;
    }
  }

  iframe {
    border-radius: 2px;
    border: none;
    display: block;
    margin-bottom: $spacing-unit;
  }

  .show-on-mobile {
    // display: none;
    @media screen and (max-width: em(960)) {
      display: block;
    }
  }

  .show-on-desktop {
    display: none;
    @media screen and (min-width: em(960)) {
      display: block;
    }
  }

  .back-to-section {
    display: block;
    margin-top: 30px;
    margin: 0 auto;
    text-align: center;
    width: auto;
    @extend button;

  }

  .project-details {
    color: var(--text-color);
    font-size: $lg-font-size;
    font-weight: $base-font-weight;
    line-height: $base-line-height;
    margin: em(5, 14) 0;

    &:first-of-type {
      margin: $spacing-unit auto em(5, 14);
    }

    &:last-of-type {
      margin: em(5, 14) auto $spacing-unit;
    }
  }
}

//Overlay styling

/* Basic styles for enlargeable images */
.case-study .enlargeable {
  transition: all 0.2s ease;
}

.case-study .enlargeable:hover {
  opacity: .8;
  cursor: pointer;
  // filter: grayscale(65%);
  @include shadow-z0;
  // transform: scale(1.006); /* Subtle hover effect */

}


/* Image zoom in overlay styles */
.overlay {
  align-items: center;
  background: rgba($brand-color-darker, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 2000;
}

.overlay img {
  @include shadow-z1;
  border-radius: 8px;
  max-height: 90%;
  max-width: 90%;
}

.overlay.hidden {
  display: none; /* Initially hide the overlay */
}

.close-button, .gallery-button {
  @include shadow-z0;
  align-items: center;
  background: none;
  border-radius: 50%;
  border: none;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  height: 40px;
  justify-content: center;
  max-width: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  padding: 0;
  line-height: initial;

  &:hover {
    background: rgba($gray-color, .8);
    color: $text-color;
    border: none;
  }
}

.gallery-button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: $white;
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
}

#prev-image {
  left: 20px;
  left: calc(50% - 40px - var(--image-width, 300px) / 2); /* Ensure it's no further than 40px left of the image */

}

#next-image {
  // right: 20px;
  right: calc(50% - 40px - var(--image-width, 300px) / 2); /* Ensure it's no further than 40px right of the image */

}

.nav-button:hover {
  background: rgba($gray-color, .8);
}