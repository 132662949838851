@media print {

  @page {
    size: auto;
    margin: 0;
  }

  // What we hide in the shadows
  .nonprint {
    display: none !important;
  }

  header,
  footer,
  .about-section {
    display: none !important;
  }

  // Complete rehaul of basics

  * {
    box-shadow: none;
    overflow: visible !important;
  }

  html,
  body {
    background: #ffff !important;
    border: none !important;
    color: $text-color !important;
    font-size: 12px;
    font-weight: $base-font-weight;
    grid-template-columns: repeat(20, 1fr) !important;
    height: 100%;
    height: 100% !important;
    line-height: 1.2;
    margin: 10px 0 0 !important;
    overflow: visible !important;
    padding: 0 !important;
    position: relative !important;
    width: 100% !important;
    page-break-inside: avoid; /* Ensure the entire block stays on one page */

    a,
    h1,
    h2,
    p {
      color: #4a4a4a !important;
      margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    p.entry,
    p.entry a {
      font-weight: 600;
      font-size: 14px;
      margin: 0;
    }
  }

  h3 {
    color: $brand-color !important;
    font-size: 16px;
    text-transform: uppercase;
    margin:12px 0 4px 0!important;
  }

  h4 {
    color: $brand-color-dark !important;
    font-size: 16px !important;

    a {
      color: $brand-color-dark !important;
    }
  }

  hr {
    border-color: $gray-color-light;
    display: none !important;

    &:last-of-type {
      display: block !important;
      max-width: 100px;
    }
  }

  //CV specific sections

  .cv-section {
    margin-top: 0 !important;
    margin: 0;
    max-height: 100vh;
    overflow: hidden;
    padding: 0;
    page-break-inside: avoid;
    width: 100%;

    &.cv-container {
      margin-top: 0 !important;
      top: 0;
      width: 100%;
      height: auto;
    }

    .cv-intro {
      display: grid;
      grid-template-columns: 55% 45% !important;
      column-gap: 0;
      width: 100%;
      height: 100%;
      align-items: stretch;
      justify-items: stretch;
      row-gap: 4px;

      h1 {
        align-items: flex-end;
        color: $brand-color;
        column-span: all;
        display: flex;
        font-size: 32px;
        grid-column: 1/ -1;
        height: auto;
        justify-content: space-between;
        margin: 0;

        span {
          display: inherit;
          font-size: 14px;
          line-height: 1;
          text-align: right;
        }
      }

      h2,
      .print-this {
        display: none;
      }

      .profile {
        grid-row: 2;

        * {
          display: none;
        }

        .profile-who {
          display: block !important;
          font-size: 20px;
          margin-bottom: 16px;
        }
      }
    }
  }

  .cv-grid {
    align-items: stretch;
    column-gap: 8%;
    grid-template-columns: 51% 41% !important;
    height: 100%;
    justify-items: stretch;
    width: 100%;

    .category {

      h4 {
        margin: 0!important;
      }

      p {
        font-size: 14px;
        margin: 0;

        span {
          color: #a0a0a0;
          font-size: 14px !important;
          font-weight: 400 !important;
        }
      }

      div {
        max-width: 100% !important;
        margin: 16px 0 0 0;
      }

      ul {
        font-size: 14px;
        &.tag-list {
          li {
            background: none;
            border: none;
            margin: 4px 0 4px;
            padding: 0;
          }
        }

        li {
          margin: 4px 0;
        }
      }

      &.xp-category {
        .title {
          font-weight: 600;
        }
        ul {
          padding-left: 0;

          li {
            padding-left: 0.8em;
            margin: 0;

            &:before {
              color: $text-color;
              font-size: 16px;
              left: 0em;
              line-height: 1;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
}
