// Footer
footer {
  font-size: $small-font-size;
  height: em(40);
  margin-top: $spacing-unit*3;
  text-align: center;
  width: 100%;
  color: var(--text-color);


  @media screen and (min-width: em(800)) {
    margin-top: $spacing-unit;
  }

  ul {
    align-content: flex-start;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: $base-line-height * 1.7;
    list-style: none;
    margin: 0;
    width: 100%;


    li {
      flex-grow: 1;
      flex-shrink: 0;
      margin: 0;
      padding: 0;
      text-align: center;

      &.hidden {
        display: none;
      }

      @media screen and (min-width: em(600)) {
        // text-align: left;
        &.hidden {
          display: block;
        }
      }

      a {
        opacity: .4;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}