// Reset some basic elements
* {
  @include content-box-sizing;
}

html,
body {
  overflow-x: hidden;
  position: relative;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

/**
 * Basic styling
 */
body {
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  -webkit-font-feature-settings: "kern" 1;
  -webkit-text-size-adjust: 100%;
  @include font-smooth;
  @include transition(
    background 0.4s ease-in-out,
    color 0.5s ease-in-out,
    border-image 0.5s ease-in-out,
    all 0.3s ease-in-out
  );
  background: -moz-linear-gradient(135deg, var(--relcolor), var(--bg-color));
  background: linear-gradient(135deg, var(--relcolor), var(--bg-color));
  border-image: var(--border-image);
  border: 2px solid $brand-color;
  color: var(--text-color);
  font-feature-settings: "kern" 1;
  font: $light-font-weight #{$base-font-size}/#{$base-line-height}
    $base-font-family;
  height: 100vh;
  letter-spacing: 0.03;
  position: relative;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding: 0 4%;
  }
}

::selection {
  background: $brand-color;
  color: $white;
}
::-moz-selection {
  background: $brand-color;
  color: $white;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
  // display: none;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
  cursor: pointer;
  // display: none;
  &:hover {
    cursor: pointer;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: $brand-color-light;
  // display: none;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 1;
}

/**
 * Images
 */
img {
  @extend .fade;
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

// p,
// li {
//   line-height: 1.58;
//   letter-spacing: -.003em;
// }

/**
 * Lists
 */
li {
  margin-bottom: 1em;
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Headings & Text
 */
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  letter-spacing: -0.015em;
  line-height: 1.15;
}

h1 {
  font-size: em(30);
  line-height: 1em;
}

h2 {
  font-size: em(24);
}

h3 {
  font-size: em(22);
  margin-top: em(30);
}

h4 {
  font-size: em(18);
  margin: em(30, 18) 0 em(20, 18);
}

h5 {
  font-size: 1em;
  font-weight: 300;
}

strong,
b {
  font-style: normal;
  font-weight: $base-font-weight;
}

em {
  font-style: normal;
  // font-weight: 600;
  color: var(--subtext-color);
}

p {
  font-size: em(20);
}

/**
 * Links and buttons
 */
a {
  @include transition(0.25s);
  color: var(--link-color);
  position: relative;
  text-decoration: none;

  &:visited {
    // color: var(--hover-color);
  }

  &:hover {
    color: var(--text-color);
    text-decoration: underline;
    cursor: pointer;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

button {
  @include shadow-z0;
  border-radius: 24px;
  border: 1px solid var(--link-color);
  padding: $spacing-unit/2 $spacing-unit;
  text-align: center;
  color: var(--link-color);
  background: none;
  font-size: em(16);

  &:hover,
  &:focus {
    @include shadow-z0;
    border: 1px solid var(--accent-color);
    cursor: pointer;
    text-decoration: none !important;
    color: var(--text-color);
    background: linear-gradient(135deg, var(--relcolor), var(--bg-color));
  }
}

/**
 * Blockquotes
 */
blockquote {
  font-size: em(14);
  font-style: italic;
  word-break: break-all;
}

/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $gray-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

// Border per section
.bl:after {
  content: "";
  border-bottom: 4px solid $brand-color;
  border-radius: 2px;
  display: block;
  margin-top: em(20);
  width: 15%;
  position: absolute;
  left: 0;
  overflow: hidden;
}

hr {
  border: none;
  border-bottom: 1px solid var(--hr-color);
  border-radius: 2px;
  margin: $spacing-unit 0;
}


//SORT THE BELOW
