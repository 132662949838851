// Header
header {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: $spacing-unit / 0.5 0 0;
  position: relative;

  .logo {
    height: 90%;
    svg {
      fill: var(--brand-color);
      
      path:not(.bird-bg) {
        @include transition(fill, 0.3s);
        fill: var(--text-color);
      }

      &:hover {
        path:not(.bird-bg) {
          fill: var(--link-color);
        }
      }
    }

    @include media-query(em(600)) {
      svg {
        max-width: em(40);
      }
    }
  } // End of logo

  .site-nav {
    line-height: $base-line-height * $base-font-size * 2.25;

    .nav-trigger {
      display: none;
    }

    .menu-icon {
      display: none;
    }

    .page-link {
      color: var(--text-color);
      font-size: em(18);
      line-height: $base-line-height;
      text-decoration: none;

      // Gaps between nav items, but not on the last one
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:hover {
        color: var(--link-color);
        cursor: pointer;
        opacity: 1;
        text-decoration: none;
      }

      &.active {
        color: var(--link-color);
        opacity: 1;
      }
    }

    @include media-query(em(700)) {
      @include shadow-z0;
      background: var(--bg-color);
      border-radius: 5px;
      border: 1px solid var(--bg-color);
      position: absolute;
      right: 0;
      text-align: right;
      top: 70px;
      z-index: 9999;

      label[for="nav-trigger"] {
        cursor: pointer;
        display: block;
        float: right;
        height: 36px;
        width: 36px;
        z-index: 9999;
      }

      .menu-icon {
        display: block;
        float: right;
        height: 26px;
        line-height: 0;
        padding-top: 10px;
        text-align: center;
        width: 36px;

        > svg path {
          fill: var(--subtext-color);
        }
      }

      input ~ .trigger {
        clear: both;
        display: none;
      }

      input:checked ~ .trigger {
        display: block;
        padding-bottom: 5px;
      }

      input:checked ~ label .menu-icon > svg path {
        fill: $brand-color;
      }

      .page-link {
        display: block;
        padding: 8px 16px;

        &:not(:last-child) {
          margin-right: 0;
        }
        margin-left: 24px;
      }
    }
  } // End of site nav
}
