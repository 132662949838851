.socials-bar {
  display: grid;
  grid-column: 2 / -2;
  grid-row-gap: em(16);
  grid-template-columns: repeat(1, 1fr);
  position: fixed;
  position: fixed;
  right: 2%;
  text-align: center;
  top: 40%;
  transform: none;
  z-index: 1000;

  @include media-query(em(600)) {
    /*mobile styling*/
    // bottom: 40px;
    height: fit-content;
    left: 50%;
    place-items: center;
    position: relative;
    right: initial;
    top: initial;
    transform: translateX(-50%);
    width: 100%;
  }

  ul {
    @include shadow-z0;
    align-items: center;
    backdrop-filter: blur(4px);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    position: relative;

    &::before {
      background-color: var(--bg-color);
      border-radius: inherit;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0.7;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }

    @include media-query(em(600)) {
      flex-direction: row;
    }

    li {
      align-items: flex-end;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 4px;

      a,
      button {
        align-items: center;
        background: transparent;
        border: none;
        border-radius: 24px;
        display: flex;
        height: 40px;
        justify-content: center;
        text-align: center;
        transition: background 0.3s ease;
        width: 40px;
        position: relative;

        svg {
          fill: var(--link-color);
          height: 20px;
          margin: 0 auto;
          width: 20px;
        }

        &:hover {
          background: var(--accent-color);
          cursor: pointer;

          svg {
            fill: var(--text-color);
          }
        }
      }

      &.footer-divider {
        position: relative;
        display: block;
        background: var(--hr-color);
        border: none;
        height: 1px;
        padding: 0;
        width: 40%;

        @include media-query(em(600)) {
          /*mobile styling*/
          height: 40%;
          width: 1px;
        }
      }
    }
  }
}

//Tooltip interaction and styling
.tooltip-trigger {
  position: relative; /* Tooltip is positioned relative to this */
}

.tooltip {
  @include shadow-z1;
  background: var(--accent-color);
  border-radius: 20px;
  color: var(--text-color);
  font-size: 0.875rem;
  right: calc(100% + 16px);
  opacity: 0; //changethis
  padding: 6px 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(10px); /* Start slightly to the right */
  transition: all 0.3 ease-out;
  visibility: hidden; //changethis
  white-space: nowrap;
  z-index: 1000;
}

.tooltip.show {
  animation: bounce 0.4s ease-out; /* Trigger bounce animation */
  opacity: 1; /* Show the tooltip */
  transform: translateY(-50%) translateX(0); /* Move tooltip into place */
  visibility: visible;
}

@keyframes bounce {
  0% {
    transform: translateY(-50%) translateX(10px); /* Start slightly to the right */
  }
  50% {
    transform: translateY(-50%) translateX(-4px); /* Slight overshoot */
  }
  100% {
    transform: translateY(-50%) translateX(0); /* Final position */
  }
}

// Switch theme switcher (DMT)
.dmt-container {
  position: relative;

  .theme-btn {
    transition: all 0.5s ease-in-out;
    padding: initial;
    box-shadow: none;

    // Theme visibility logic
    .dark &.dark,
    .light &.light {
      display: none;
    }
    .dark &.light,
    .light &.dark {
      display: block;
    }

    @media (prefers-color-scheme: dark) {
      &.dark {
      display: none;
      }
    }

    // Light and dark default states
    &.dark {
      stroke: var(--link-color);
      .stars {
        opacity: 0;
      }
    }

    &.light {
      svg {
        stroke: var(--link-color);
      }
    }

    // Light and dark hover states
    &.light:hover svg {
      animation: rotation 20s cubic-bezier(0, -0.01, 1, 1) infinite;
      stroke: var(--text-color);
      fill: var(--text-color);
    }

    &.dark:hover svg .stars {
      &.star1 {
        animation: twinkle 3s linear infinite;
      }
      &.star2 {
        animation: twinkle 5s linear infinite;
      }
    }

    // SVG styles
    svg {
      height: 24px;
      object-fit: cover;
      width: 24px;
      fill: transparent;

      &.light,
      &.dark {
        height: 20px;
        width: 20px;

        .stars {
          opacity: 0;
        }

        &:hover {
          fill: var(--text-color);

          .stars {
            fill: var(--text-color);
          }
        }
      }
    }
  }

  // @include media-query(em(600)) {
  //   bottom: initial;
  //   display: block;
  //   position: relative;
  //   right: initial;
  //   text-align: right;
  // }
}

.link--herse {
	font-family: $base-font-family!important;
	font-size: 1.375rem!important ;
	font-weight: bold!important ;
}

.link__graphic--arc {
	top: 73%!important ;
	left: -23%!important ;
}

.link__graphic--arc path {
	stroke-dasharray: 1!important ;
	stroke-dashoffset: 1!important ;
	transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1)!important ;
}

.link:hover .link__graphic--arc path {
	stroke-dashoffset: 0!important ;
	transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1)!important ;
	transition-duration: 0.3s!important ;
}
