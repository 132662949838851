@charset "utf-8";

// Define defaults for each variable.

$base-font-family: 'Karla', "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$header-font-family: 'Rubik', "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$light-font-weight: 300 !default;
$xlight-font-weight: 200 !default;
$small-font-size:  $base-font-size * .875 !default;
$lg-font-size: $base-font-size * 1.125 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;
$table-text-align: left !default;
$content-width: 1160px;

// Import partials.
@import
    "particles/themes",
    "particles/mixins",
    "particles/base",
    "particles/socials-bar",
    "particles/header",
    "particles/layout",
    "particles/footer",
    "particles/shots",
    "particles/about",
    "particles/cv",
    "particles/case_studies",
    "particles/cv_print"
;