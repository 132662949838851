.grid-base {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: minmax(auto, em(130)) auto minmax(auto, em(35));

  header,
  footer,
  main {
    grid-column: 2 / -2;
    min-width: 0;
  }
}

main > section,
main > article {
  @extend .fade;
  margin: $spacing-unit * 1.6 auto;

  @media screen and (min-width: em(800)) {
    margin: $spacing-unit * 4 auto $spacing-unit * 2;
  }
}

.introduction {
    .introduction-content {
    max-width: em(600, 16);

    h2 {
      color: $gray-color-dark;
      font-weight: 400;
    }
  }
}

.about-section .bio p {
  font-size: 20px;
}