// CV Styles
main > .cv-section {
  margin-top: em(40);

  @media (min-width: em(900)) {
    margin-top: em(100);
  }

  hr {
    display: block;
    @media (min-width: em(900)) {
      // display: none;
    }
  }
}

.cv-intro {
  align-items: center;
  column-gap: 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto auto;
  row-gap: 12px;
  width: 100%;

  h1,
  h2 {
    column-span: 1;
    margin-bottom: 12px;
    width: auto;
  }
  h1 span {
    display: none;
  }

  h1 {
    grid-column: 1/2;
    grid-row: 1;
    margin: 0;
  }

  h2 {
    grid-column: 1/2;
    grid-row: 2;
    margin: 0;
  }

  p {
    font-size: 16px;
  }

  .print-this {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    align-self: start;
    grid-column:1/3;
    grid-row: 4 / span 2;
    justify-self: start;
    margin-bottom: 20px;
  }

  .profile {
    grid-column: 1 / -1;
    grid-row: 3;
    margin: 0;
  }
}

.cv-grid {
  align-content: start;
  column-gap: 10%;
  display: grid;
  grid-auto-flow: row dense;
  grid-auto-rows: minmax(max-content, auto);
  grid-template-columns: 100%;
  grid-template-rows: minmax(max-content, auto);
  justify-content: start;

  @media (min-width: em(768)) {
    grid-template-columns: 55% 40%;
  }

  @media (min-width: em(1300)) {
    column-gap: 2%;
  }

  .category {
    h3 {
      margin: em(24) 0 em(8);
    }

    h4 {

      &.entry {
        color: var(--text-color);
        font-size: $lg-font-size;
        margin: 0;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        margin-top: 10px;


        svg {
          vertical-align: middle;
          color: var(--link-color);
        }
      }
    }

    hr {
      margin: 10px 0 10px 0;
      width: 25%;
      // display: none;
    }

    div {
      @media (min-width: em(768)) {
        max-width: em(400);
      }
    }

    p {
      font-size: em(16);
      margin-bottom: em(10);

      &.title {
        margin: em(5) 0 em(10);
        width: 100%;

        span {
          display: block;
          font-size: $base-font-size;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;

      li {
        display: inline-block;
        line-height: 1.3em;
      }

      &.tag-list {
        li {
          padding: 4px 8px;
          border-radius: 8px;
          border: 1px solid var(--hover-color);
          background: var(--relcolor);
          margin: 4px 4px 4px 0;
        }
      }
    }

    &.skills-category {
      position: relative;

      li {
        margin: 0 4px 0 0;

        &:after {
          content: " ";
          margin: 0 0 0 4px;
        }
      }
    }

    &.xp-category {
      grid-row: 1/-4;
      grid-row: span 5;
      list-style: disc;

      ul {
        list-style: none; 
        margin: 0; 
        padding-left: 0; 

        li {
          position: relative;

          &::before {
            color: $brand-color-light;
            content: "•";
            font-size: 2em;
            left: -0.5em;
            line-height: 0.5;
            position: absolute;
            vertical-align: top;
          }
        }
      }
    }
  }
}