// Mixins, functions, animations and effects

@mixin content-box-sizing {
  webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// (EM) Function for pixels to ems
$em-base: 16; // Default
@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1em;
}

// Function to render hex into RGBA
@function hex-to-rgba($hex, $alpha: 1) {
  @return red($hex), green($hex), blue($hex), $alpha;
}

@mixin img-render {
  image-rendering: -moz-auto; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

@mixin font-smooth {
  -moz-font-smoothing: subpixel-antialiased;
  -ms-font-smoothing: subpixel-antialiased;
  -o-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
}

//Transition
@mixin transition($values...) {
  -moz-transition: $values;
  -ms-transition: $values;
  -o-transition: $values;
  -webkit-transition: $values;
  transition: $values;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Shadows
@mixin shadow-z0 {
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
}

@mixin shadow-z1 {
  -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

// Frosted glass effect
@mixin frosted-glass(
  $blur: 10px,
  $bg-color: rgba(var(--bg-color), 0.7) /* Corrected syntax for default value */
) {
  background: $bg-color;
  backdrop-filter: blur($blur);
  -webkit-backdrop-filter: blur($blur); /* For WebKit-based browsers */
}

@mixin linearGradient($top, $bottom) {
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $top),
    color-stop(100%, $bottom)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $top 0%,
    $bottom 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

// Simple fade animation
.fade {
  animation-name: fade;
  animation-duration: 2s;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Rotate indefinitely animation
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// Twinkle animation
.twinkle {
  animation-name: twinkle;
  animation-duration: 2s;
}

@keyframes twinkle {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    // transform: scale(0.5);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// Mixin for subtle gradient animation
@mixin gradient-animation(
  $start-color,
  $middle-color,
  $end-color,
  $direction: 135deg,
  $duration: 20s
) {
  background: linear-gradient(
    $direction,
    $start-color,
    $middle-color,
    $end-color
  );
  background-size: 300% 300%;
  animation: gradientAnimation $duration ease-in-out infinite;

  @keyframes gradientAnimation {
    0% {
      background-position: 50% 0%;
    }
    25% {
      background-position: 50% 50%;
    }
    50% {
      background-position: 50% 100%;
    }
    75% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 50% 0%;
    }
  }
}
