// Dribbble shots styling
#dribbble-feed {
  margin: $spacing-unit auto;
}

.view-more {
  display: block;
  font-size: $small-font-size;
  margin: 0 auto;
  padding: $spacing-unit / 2 0;
  text-align: center;
  width: 100%;
  max-width: em(320);
}

.latest-shots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  .shot {
    max-width: em(300);
    position: relative;
    margin: 0;
    @include transition (ease-in-out, .7s);

    a{
      display: block;
      height: 100%;
      width: 100%;

      &:after {
        @include transition(ease-in, .4s);
        background: rgba($brand-color,.2);
        content: "";
        display: block;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &:hover {
        cursor: pointer;

        &:after {
          opacity: 1;
        }

        img {
          filter: saturate(150%);
        }
      }
     }

    @media only screen and (max-width: em(320)) {
      width: 100%;
    }

    img {
      @extend .fade;
      @include shadow-z0;
      @include transition(ease-in, .4s);
      border-radius: 8px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.03), 0 20px 30px rgba(0, 0, 0, 0.1);
      box-shadow: none;
      filter: saturate(90%);
    }
  }
}

  @supports (display: grid) {
    .latest-shots {
      align-items: center;
      display: grid;
      grid-auto-rows: auto;
      grid-gap: em(20);
      grid-template-columns: repeat(3, auto);
      justify-items: flex-start;

      @media only screen and (max-width: em(500)) {
        grid-template-columns: repeat(2, auto);
      }

      @media only screen and (max-width: em(420)) {
        grid-template-columns: repeat(1, auto);
      }

      .shot {
        max-width: none;

        @media only screen and (max-width: em(2130)) {
          &:nth-last-of-type(-n+2) {
            display: none;
          }
        }
      }
    }
  }